<template>
  <template v-if="facilities.length === 0">
    <SectionEmptyState>
      {{ $t("accommodations.manage.facilities.empty") }}
    </SectionEmptyState>
  </template>
  <template v-else>
    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <template v-for="facility in facilities">
        <div class="text-sm text-slate-700">
          {{ $t(`accommodations.facilities.facility.${facility}`) }}
        </div>
      </template>
    </div>
  </template>
</template>

<script lang="ts" setup>
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  facilities: string[];
}>();

const { t: $t } = useI18n();
</script>
