<template>
  <div class="group relative flex grow items-stretch focus-within:z-10">
    <div class="flex">
      <button
        :class="{ 'border-slate-300 focus:border-primary': !hasErrors, 'border-red-500 focus:border-red-500': hasErrors }"
        class="ring-none h-full w-7 rounded-l border text-sm leading-none text-slate-800 hover:bg-slate-50 focus:border-2 focus:border-primary focus:outline-hidden"
        type="button"
        @click.prevent="decrease"
      >
        <FontAwesomeIcon
          class="m-auto h-3 w-3 fill-current"
          icon="minus-solid"
        />
      </button>
    </div>
    <FormText
      :rounded="false"
      class="border-x-transparent focus:border-x focus:outline-0 focus:ring-inset"
      step="1"
      v-bind="$props"
      @update:model-value="input = parseInt(String($event))"
    />
    <div class="flex">
      <button
        :class="{ 'border-slate-300 focus:border-primary': !hasErrors, 'border-red-500 focus:border-red-500': hasErrors }"
        class="ring-none h-full w-7 rounded-r border text-sm leading-none text-slate-800 hover:bg-slate-50 focus:border-2 focus:outline-hidden"
        type="button"
        @click.prevent="increase"
      >
        <FontAwesomeIcon
          class="m-auto h-3 w-3 fill-current"
          icon="plus-solid"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Props } from "@/contexts/shared/ui/components/form/FormText.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<
    Props & {
      modelValue: number | null | undefined;
      min?: number;
      max?: number;
    }
  >(),
  {
    type: "number",
    min: undefined,
    max: undefined,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: number | null | undefined];
}>();

const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const input = computed<number>({
  get: () => props.modelValue ?? 0,
  set: (value) => emit("update:modelValue", value || value === 0 ? Number.parseInt(String(value)) : null),
});

const decrease = () => {
  if (props.min === undefined || input.value > props.min) {
    input.value--;
  }
};
const increase = () => {
  if (props.max === undefined || input.value < props.max) {
    input.value++;
  }
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
