<template>
  <div :class="cn('relative h-8 border-b border-r border-slate-200', { 'bg-white': !isWeekend })">
    <template v-if="isFirstAvailability">
      <div
        :class="cn('absolute left-0 top-0 z-10 my-1 flex h-6', availabilityBackground)"
        :style="{ width: `calc(4rem * ${availabilityDays})` }"
      >
        <div class="my-auto truncate pl-1.5 text-xs font-normal text-white">
          {{ $t(`bookings.availability.${availability}`) }}
        </div>
      </div>
    </template>
  </div>
  <button
    type="button"
    :data-dns="day"
    @click.prevent="$emit('select')"
    @dragenter.prevent="$emit('drag')"
  >
    <div :class="cn('flex h-8 border-b border-r border-slate-200 last:border-b-0', { 'bg-white': !isWeekend })">
      <div class="my-auto w-full text-center text-sm">{{ minimumStay }}</div>
    </div>
    <template
      v-for="(item, index) in prices"
      :key="`calendar_day_${index}_los${item}`"
    >
      <div :class="cn('flex h-10 border-b border-r border-slate-200 last:border-b-0', { 'bg-white': !isWeekend })">
        <div class="my-auto w-full text-center text-sm">{{ item ? (Number(item) / 100).toFixed(2) : "-" }}</div>
      </div>
    </template>
  </button>
</template>

<script setup lang="ts">
import type { AccommodationPrices, AccommodationPricesDayStatus } from "@/contexts/accommodations/models/AccommodationPrices";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import cn from "@/contexts/shared/utils/cn";

const props = defineProps<{
  day: string;
  availabilities: AccommodationPrices["availabilities"];
  availability: AccommodationPricesDayStatus;
  prices: Record<string, number | null>;
  minimumStay: number;
}>();

defineEmits<{
  drag: [];
  select: [];
}>();

const { t: $t } = useI18n();
const dayjs = useDayjs();

const isWeekend = computed(() => dayjs(props.day).day() === 0 || dayjs(props.day).day() === 6);
const isFirstAvailability = computed(() => {
  const day = dayjs(props.day);
  const current = props.availabilities[day.format("YYYY-MM-DD")];
  const previous = props.availabilities[day.subtract(1, "day").format("YYYY-MM-DD")];
  if (!previous) {
    return true;
  }
  return previous !== current;
});
const availabilityDays = computed(() => {
  const dates = Object.keys(props.availabilities).sort();
  let count = 0;
  let startCounting = false;

  for (const currentDate of dates) {
    if (currentDate === dayjs(props.day).format("YYYY-MM-DD")) {
      startCounting = true;
    }
    if (startCounting) {
      if (props.availabilities[currentDate] === props.availability) {
        count++;
      } else {
        break;
      }
    }
  }

  return count;
});
const availabilityBackground = computed(() => {
  switch (props.availability) {
    case "booked":
      return "bg-blue-500";
    case "available":
      return "bg-green-500";
    case "locked_by_owner":
      return "bg-orange-500";
    case "locked_by_manager":
      return "bg-red-500";
    default:
      return "bg-slate-500";
  }
});
</script>
