<template>
  <div class="flex">
    <div
      ref="element"
      class="flex"
    >
      <slot
        name="button"
        v-bind="{ open, close, toggle }"
      />
      <teleport to="body">
        <div
          v-if="display"
          ref="floating"
          :style="floatingStyles"
          class="absolute z-10 max-w-xs rounded-sm border border-slate-200 bg-white px-3 py-2 text-sm font-normal drop-shadow-xl md:max-w-md lg:max-w-2xl"
        >
          <div
            ref="floatingArrow"
            :class="{ '-bottom-1.5 border-b border-r': ['top', 'top-start', 'top-end'].includes(placement), '-top-1.5 border-l border-t': ['bottom', 'bottom-start', 'bottom-end'].includes(placement) }"
            class="absolute h-2.5 w-2.5 rotate-45 rounded-xs border-slate-200 bg-white"
          />
          <slot v-bind="{ open, close, toggle }" />
        </div>
      </teleport>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { arrow, autoPlacement, offset, shift, useFloating } from "@floating-ui/vue";
import { onClickOutside, onKeyDown } from "@vueuse/core";

const props = defineProps<{
  alignment?: "start" | "end";
}>();

const display = ref(false);
const element = ref<HTMLElement>();
const floating = ref<HTMLElement>();
const floatingArrow = ref<HTMLElement>();

const { floatingStyles, middlewareData, placement } = useFloating(element, floating, {
  middleware: [
    shift(),
    offset(8),
    arrow({ element: floatingArrow }),
    autoPlacement({
      alignment: props.alignment,
      autoAlignment: false,
      allowedPlacements: ["top", "top-start", "top-end", "bottom", "bottom-start", "bottom-end"],
    }),
  ],
});
watch(
  () => middlewareData.value,
  (value) => {
    if (value.arrow) {
      Object.assign(floatingArrow.value?.style ?? {}, {
        left: `${value.arrow.x}px`,
        bottom: `${-(floatingArrow.value?.offsetHeight ?? 0) / 2}px`,
      });
    }
  },
);

onKeyDown("Escape", (e) => {
  if (display.value) {
    close();
    e.preventDefault();
  }
});
onClickOutside(floating, () => close(), { ignore: [element] });

const open = () => {
  display.value = true;
};
const close = () => {
  display.value = false;
};
const toggle = () => {
  !display.value ? open() : close();
};

defineExpose({ open, close, toggle });
</script>
