<template>
  <div class="overflow-x-auto border border-slate-200 text-slate-800">
    <div class="flex min-w-full">
      <div class="flex shrink-0 flex-col">
        <div class="max-w-1/3 h-10 border-x border-b border-slate-200 bg-slate-100 md:w-48" />
        <template
          v-for="(accommodation, index) in accommodations"
          :key="`calendar_row_${index}`"
        >
          <div class="flex h-10 border-b border-r border-slate-200 px-2 last:border-b-0">
            <div class="my-auto">{{ accommodation.acronym }}</div>
          </div>
        </template>
      </div>
      <div class="h-full flex-1 overflow-x-auto bg-slate-100">
        <div class="relative flex h-full">
          <template
            v-for="(day, index) in days"
            :key="index"
          >
            <div class="flex flex-col">
              <div class="flex h-10 w-16 border-b border-r border-slate-200">
                <div class="m-auto">
                  <div class="text-center text-xs text-slate-500">
                    {{ $t(`shared.dayjs.weekdays_short`).split("_")[day.getUTCDay()] }}
                  </div>
                  <div class="text-center text-sm text-slate-800">
                    {{ day.getDate() }}
                  </div>
                </div>
              </div>
              <template
                v-for="(accommodation, index) in accommodations"
                :key="`calendar_day_${index}`"
              >
                <BookingCleaningsPlannerTableDay
                  :date-range="modelValue.date"
                  :accommodation="accommodation"
                  :bookings="bookings.filter((booking) => booking.accommodation_id === accommodation.id)"
                  :day="day"
                  @updated-booking-cleaning="$emit('updatedBookingCleaning', $event)"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import type { SearchBookingCleaningsPlannerCriteria } from "@/contexts/booking-cleanings/models/SearchBookingCleaningsPlannerCriteria";
import BookingCleaningsPlannerTableDay from "@/contexts/booking-cleanings/ui/components/BookingCleaningsPlannerTableDay.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import useCalendar from "@/contexts/shared/composables/useCalendar";

defineProps<{
  accommodations: Accommodation[];
  bookings: Booking[];
}>();

defineEmits<{
  updatedBookingCleaning: [value: { booking: Booking; cleaning: BookingCleaning }];
}>();

const modelValue = defineModel<SearchBookingCleaningsPlannerCriteria>({ default: { date: { start: new Date(), end: new Date() } } });

const { t: $t } = useI18n();
const { days } = useCalendar<SearchBookingCleaningsPlannerCriteria>({ value: modelValue, key: "date" });
</script>
