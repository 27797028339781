<template>
  <div class="flex items-center justify-between">
    <span class="flex grow flex-col">
      <label
        :for="id"
        class="text-sm font-medium leading-6 text-slate-700"
      >
        <slot />
      </label>
      <template v-if="description">
        <div class="text-sm text-slate-500">{{ $t(description) }}</div>
      </template>
    </span>
    <FormSwitch
      v-model="modelValue"
      :name="name"
    />
  </div>
</template>

<script setup lang="ts">
import FormSwitch from "@/contexts/shared/ui/components/form/FormSwitch.vue";
import { v4 as uuid } from "uuid";

const props = defineProps<{
  id: string;
  name: string;
  description?: string;
  errors?: string[];
}>();

const modelValue = defineModel<boolean>({ default: false });

const { t: $t } = useI18n();

provide<string>("id", props.id ?? uuid());
</script>
