<template>
  <DropdownMenuItem v-slot="{ close }">
    <button
      :class="cn('block w-full truncate whitespace-nowrap px-4 py-2 text-left text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 disabled:cursor-default disabled:text-slate-700/30 hover:disabled:text-slate-900/30', $props.class)"
      type="button"
      :disabled="disabled"
      @click.prevent="click(close)"
    >
      <slot />
    </button>
  </DropdownMenuItem>
</template>

<script lang="ts" setup>
import DropdownMenuItem from "@/contexts/shared/ui/components/dropdown/DropdownMenuItem.vue";
import cn from "@/contexts/shared/utils/cn";

defineProps<{
  disabled?: boolean;
  class?: string;
}>();

const emit = defineEmits<{
  click: [...args: unknown[]];
}>();

const click = (close: () => void) => {
  emit("click");
  close();
};
</script>
