@ -0,0 +1,77 @@
<template>
  <div class="relative inline-block text-left">
    <div ref="element">
      <slot name="button">
        <DropdownMenuButton v-slot="{ toggle }">
          <button
            class="flex items-center rounded-sm border border-transparent px-1 py-0.5 hover:border-slate-300 hover:bg-white focus:border-slate-300 focus:bg-white focus:outline-hidden focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-slate-100"
            @click.prevent="toggle"
          >
            <FontAwesomeIcon
              class="h-5 w-5 fill-slate-700"
              icon="ellipsis-light"
            />
          </button>
        </DropdownMenuButton>
      </slot>
    </div>
    <teleport to="body">
      <div
        v-if="isOpen"
        ref="floating"
        :style="floatingStyles"
        class="absolute z-20 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden"
      >
        <div class="py-1">
          <slot />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import DropdownMenuButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuButton.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import { autoPlacement, offset, shift, useFloating } from "@floating-ui/vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

const isOpen = ref(false);
const element = ref<HTMLElement>();
const floating = ref<HTMLElement>();

onClickOutside(
  element,
  () => {
    isOpen.value = false;
  },
  { ignore: [floating] },
);
const { floatingStyles } = useFloating(element, floating, {
  placement: "bottom-end",
  middleware: [
    shift(),
    offset(8),
    autoPlacement({
      autoAlignment: false,
      allowedPlacements: ["top-end", "bottom-end"],
    }),
  ],
});

provide("open", () => {
  isOpen.value = true;
});
provide("close", () => {
  isOpen.value = false;
});
provide("toggle", () => {
  isOpen.value = !isOpen.value;
});
</script>
