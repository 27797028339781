<template>
  <div :class="clsx('relative flex h-10 w-16 border-b border-r border-slate-200 last:border-b-0', { 'bg-white': !isWeekend && !isToday, 'bg-slate-100': isWeekend && !isToday, 'bg-amber-50': isToday })">
    <div class="m-auto">
      <div />
    </div>
    <template v-if="booking">
      <div class="absolute left-0 top-0 z-10 py-2">
        <div
          class="flex h-6 max-w-full items-center justify-between truncate bg-primary px-2 text-sm font-normal text-white data-[future=false]:rounded-r-2xl data-[previous=false]:rounded-l-2xl"
          :style="{ width: `calc(4rem * ${bookingDays})` }"
          :data-previous="isPreviousBooking"
          :data-future="isFutureBooking"
          :data-booking="booking.code"
        >
          <div class="flex">
            <BookingCleaningsPlannerSelect
              v-if="!isPreviousBooking"
              :booking="booking"
              assoc="check_in"
              @updated-booking-cleaning="$emit('updatedBookingCleaning', $event)"
            />
          </div>
          <div class="flex">
            <BookingCleaningsPlannerSelect
              v-if="!isFutureBooking"
              :booking="booking"
              assoc="check_out"
              @updated-booking-cleaning="$emit('updatedBookingCleaning', $event)"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { BookingCleaning } from "@/contexts/booking-cleanings/models/BookingCleaning";
import BookingCleaningsPlannerSelect from "@/contexts/booking-cleanings/ui/components/BookingCleaningsPlannerSelect.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { DateRange } from "@/contexts/shared/models/DateRange";
import { clsx } from "clsx";

const props = defineProps<{
  day: Date;
  dateRange: DateRange;
  accommodation: Accommodation;
  bookings: Booking[];
}>();

defineEmits<{
  updatedBookingCleaning: [value: { booking: Booking; cleaning: BookingCleaning }];
}>();

const dayjs = useDayjs();

const isToday = computed(() => dayjs(props.day).isSame(dayjs(), "day"));
const isWeekend = computed(() => dayjs(props.day).day() === 0 || dayjs(props.day).day() === 6);
const booking = computed<Booking | undefined>(() => {
  return props.bookings
    .filter((item) => item.accommodation_id === props.accommodation.id)
    .find((booking) => {
      const checkIn = new Date(booking.check_in);
      return dayjs(props.day).isSameOrBefore(props.dateRange.from, "day") ? dayjs(checkIn).isSameOrBefore(props.day, "day") : dayjs(checkIn).isSame(props.day, "day");
    });
});
const bookingDays = computed(() => {
  if (!booking.value) {
    return 0;
  }
  const checkIn = new Date(booking.value.check_in);
  const checkOut = new Date(booking.value.check_out);
  const start = dayjs(checkIn).isSameOrBefore(props.dateRange.from) ? dayjs(props.dateRange.from).startOf("day") : checkIn;
  const end = dayjs(checkOut).isSameOrAfter(props.dateRange.to) ? dayjs(props.dateRange.to).endOf("day").add(1, "day") : checkOut;
  return dayjs(end).diff(start, "day");
});
const isPreviousBooking = computed(() => {
  if (!booking.value) {
    return false;
  }
  const checkIn = new Date(booking.value.check_in);
  return dayjs(checkIn).isBefore(props.dateRange.from, "day");
});
const isFutureBooking = computed(() => {
  if (!booking.value) {
    return false;
  }
  const checkOut = dayjs(new Date(booking.value.check_out)).startOf("day").subtract(1, "day");
  return dayjs(checkOut).isAfter(props.dateRange.to, "day");
});
</script>
