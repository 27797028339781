<template>
  <div class="w-full divide-y divide-slate-200 border border-slate-200 bg-slate-200 leading-6">
    <div class="bg-white py-1.5 text-center font-medium text-slate-800">
      {{ $t(`shared.calendar.months.${month}`) }}
    </div>
    <div class="grid grid-cols-7 gap-px">
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.1") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.2") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.3") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.4") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.5") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.6") }}</div>
      <div class="bg-white py-1 text-center text-sm font-medium text-slate-700">{{ $t("shared.calendar.weekdays_min.0") }}</div>
    </div>
    <div class="grid grid-cols-7 gap-px">
      <template
        v-for="(day, index) in dates"
        :key="index"
      >
        <template v-if="day.isCurrentMonth">
          <AccommodationsCalendarDay
            :booking="getDayBooking(day.date)"
            :date="day.date"
            :is-empty="!notEmptyDates.includes($dayjs(day.date).format('YYYY-MM-DD'))"
            :price="getDayPrice(day.date)"
            :status="getDayStatus(day.date)"
          />
        </template>
        <template v-else>
          <div class="h-10 bg-slate-50" />
        </template>
      </template>
      <template v-if="dates.length / 7 < 6">
        <template
          v-for="item in new Array((6 - dates.length / 7) * 7)"
          :key="item"
        >
          <div class="h-10 bg-slate-50" />
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AccommodationCalendarDayStatus, AccommodationCalendarDays } from "@/contexts/accommodations/models/AccommodationCalendar";
import AccommodationsCalendarDay from "@/contexts/accommodations/ui/components/AccommodationsCalendarDay.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import useDayjs from "@/contexts/shared/composables/useDayjs";

const props = defineProps<{
  month: number;
  year: number;
  days: AccommodationCalendarDays;
  bookings: Booking[];
}>();

const { t: $t } = useI18n();
const dayjs = useDayjs();

const dates = computed<{ date: Date; isCurrentMonth?: boolean; isSelected?: boolean; isDisabled?: boolean }[]>(() => {
  const dates = [];
  const start = dayjs()
    .set("year", props.year)
    .set("month", props.month - 1)
    .startOf("month")
    .startOf("week");
  const end = dayjs()
    .set("year", props.year)
    .set("month", props.month - 1)
    .endOf("month")
    .endOf("week");
  for (let d = start.toDate(); d <= end.toDate(); d.setDate(d.getDate() + 1)) {
    const dl = dayjs(d).clone();
    dates.push({ date: dl.toDate(), isCurrentMonth: dl.month() === props.month - 1 });
  }
  return dates;
});
const notEmptyDates = computed<string[]>(() => Object.keys(props.days).filter((item) => props.days[item]?.status !== null));

const getDayStatus = (date: Date): AccommodationCalendarDayStatus | null => props.days[dayjs(date).format("YYYY-MM-DD")]?.status ?? null;
const getDayPrice = (date: Date): number | null => props.days[dayjs(date).format("YYYY-MM-DD")]?.price ?? null;
const getDayBooking = (date: Date): Booking | undefined => props.bookings.find((item) => dayjs(date).isSame(item.check_in, "date") || dayjs(date).isSame(dayjs(item.check_out).subtract(1, "days"), "date") || dayjs(date).isBetween(item.check_in, item.check_out, "date"));
</script>
