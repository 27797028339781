<template>
  <Widget>
    <WidgetTitle>
      {{ $t("analytics.departures_calendar.title") }}
    </WidgetTitle>
    <WidgetContent>
      <div class="space-y-4 border-b border-slate-200 p-4">
        <div class="flex justify-between">
          <div class="font-medium text-slate-800">
            {{ monthTitle }}
          </div>
          <div class="flex gap-2">
            <ButtonWithIcon
              class="btn-white"
              icon="angle-left-solid"
              @click="prevWeek()"
            />
            <ButtonWithIcon
              class="btn-white"
              icon="angle-right-solid"
              @click="nextWeek()"
            />
          </div>
        </div>
        <div class="grid grid-cols-7 justify-items-center gap-4">
          <template
            v-for="(day, index) in days"
            :key="index"
          >
            <div class="space-y-0.5">
              <div class="text-center text-xs text-slate-500">
                {{ $t(`shared.dayjs.weekdays_short`).split("_")[day.getUTCDay()] }}
              </div>
              <div class="text-center text-sm text-slate-800">
                {{ day.getDate() }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        ref="target"
        class="h-96"
      >
        <template v-if="searchBookingsRequest.isLoading">
          <LoadingSection
            class="h-full"
            custom-height
          />
        </template>
        <template v-else-if="searchBookingsRequest.hasErrors">
          <ListErrorState
            class="h-full"
            custom-height
          />
        </template>
        <template v-else-if="searchBookingsRequest.data.data.length === 0">
          <ListEmptyState
            description="analytics.departures_calendar.empty.description"
            icon="calendar-days-solid"
            title="bookings.empty.title"
          />
        </template>
        <template v-else>
          <div class="h-full overflow-y-auto">
            <ListTable>
              <ListTableHeader>
                <ListTableHeaderCell class="sticky top-0 w-full bg-white/75 backdrop-blur-sm backdrop-filter">
                  {{ $t("bookings.props.accommodation_id") }}
                </ListTableHeaderCell>
                <ListTableHeaderCell class="sticky top-0 bg-white/75 backdrop-blur-sm backdrop-filter">
                  {{ $t("bookings.props.check_out") }}
                </ListTableHeaderCell>
              </ListTableHeader>
              <ListTableBody>
                <template
                  v-for="booking in searchBookingsRequest.data.data"
                  :key="booking.id"
                >
                  <ListTableBodyRowLink :to="{ name: 'bookings.manage', params: { id: booking.id } }">
                    <ListTableBodyCell>{{ booking.accommodation?.acronym }}</ListTableBodyCell>
                    <ListTableBodyCell class="text-right">
                      {{ $t(`shared.dayjs.weekdays`).split("_")[booking.check_out.getUTCDay()] }}
                      {{ booking.check_out.toLocaleDateString() }}
                    </ListTableBodyCell>
                  </ListTableBodyRowLink>
                </template>
              </ListTableBody>
            </ListTable>
          </div>
        </template>
      </div>
    </WidgetContent>
  </Widget>
</template>

<script lang="ts" setup>
import useBookings from "@/contexts/bookings/composables/useBookings";
import type { Booking } from "@/contexts/bookings/models/Booking";
import type { SearchBookingsCriteria } from "@/contexts/bookings/models/SearchBookingsCriteria";
import useCalendar from "@/contexts/shared/composables/useCalendar";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useRequest from "@/contexts/shared/composables/useRequest";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import Widget from "@/contexts/shared/ui/components/widget/Widget.vue";
import WidgetContent from "@/contexts/shared/ui/components/widget/WidgetContent.vue";
import WidgetTitle from "@/contexts/shared/ui/components/widget/WidgetTitle.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { searchBookings } = useBookings();
const accountBus = useEventBus<string>("account");

const target = ref<HTMLElement>();
const criteria = ref<SearchBookingsCriteria>({
  check_out: { from: dayjs().startOf("day").toDate(), to: dayjs().add(6, "days").toDate() },
  order_by: "check_out",
  sort: "asc",
  per_page: 1000,
});

const { days, monthTitle, getPrev7Days, getNext7Days } = useCalendar<SearchBookingsCriteria>({ value: criteria, key: "check_out" });
const visibility = useVisibility(target, () => searchBookingsRequest.fetch());

accountBus.on(() => visibility.reset());

const prevWeek = () => {
  criteria.value.check_out = getPrev7Days();
  searchBookingsRequest.fetch();
};
const nextWeek = () => {
  criteria.value.check_out = getNext7Days();
  searchBookingsRequest.fetch();
};

const searchBookingsRequest = useRequest<Pagination<Booking>>({
  initialLoading: true,
  value: emptyPagination<Booking>(),
  promise: () => searchBookings(criteria.value),
});
</script>
