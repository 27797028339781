<template>
  <div class="mb-6 w-full rounded-sm bg-white p-8 shadow-xl md:p-10">
    <div class="mb-6 text-xl font-semibold text-slate-900">
      {{ $t("auth.login.title") }}
    </div>
    <form
      class="space-y-6"
      @submit.prevent="submit"
    >
      <FormGroup
        id="email"
        :errors="loginRequest.errors.email"
      >
        <FormLabel>{{ $t("auth.login.form.email") }}</FormLabel>
        <FormInput>
          <FormText
            v-model="form.email"
            :padding="false"
            autocomplete="email"
            name="email"
            tabindex="1"
          />
        </FormInput>
      </FormGroup>
      <FormGroup
        id="password"
        :errors="loginRequest.errors.password"
      >
        <div class="flex items-center justify-between">
          <FormLabel>{{ $t("auth.login.form.password") }}</FormLabel>
          <RouterLink
            :to="{ name: 'reset' }"
            class="text-sm text-primary hover:text-slate-800"
            tabindex="3"
          >
            {{ $t("auth.login.reset") }}
          </RouterLink>
        </div>
        <FormInput>
          <FormText
            v-model="form.password"
            :padding="false"
            autocomplete="current-password"
            name="password"
            tabindex="1"
            type="password"
          />
        </FormInput>
      </FormGroup>
      <FormSubmit
        id="submit"
        :is-loading="loginRequest.isLoading"
      >
        {{ $t("auth.login.form.submit") }}
      </FormSubmit>
    </form>
    <div class="mt-6">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-slate-200" />
        </div>
        <div class="relative flex justify-center text-sm font-medium leading-6">
          <span class="bg-white px-6 text-slate-900">{{ $t("auth.login.oauth") }}</span>
        </div>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-4">
        <AuthOAuthButton
          provider="google"
          :is-loading="oAuthLoginRequest.isLoading"
          @click="oAuthLoginRequest.fetch('google')"
        />
        <AuthOAuthButton
          provider="apple"
          :is-loading="oAuthLoginRequest.isLoading"
          @click="oAuthLoginRequest.fetch('apple')"
        />
      </div>
    </div>
  </div>
  <div class="px-4">
    <p class="text-sm text-slate-700">
      {{ $t("auth.register.no_account") }}
      <RouterLink
        :to="{ name: 'register' }"
        class="text-primary hover:text-slate-800"
      >
        {{ $t("auth.register.sign_up") }}
      </RouterLink>
    </p>
  </div>
</template>

<script lang="ts" setup>
import useAuth from "@/contexts/auth/composables/useAuth";
import type { AuthOAuthProvider } from "@/contexts/auth/model/AuthOAuthProvider";
import AuthOAuthButton from "@/contexts/auth/ui/components/AuthOAuthButton.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSubmit from "@/contexts/shared/ui/components/form/FormSubmit.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import type { AxiosResponse } from "axios";

const { t: $t } = useI18n();
const route = useRoute();
const router = useRouter();
const { login, fetchUser, oAuthLogin } = useAuth();
const { errorNotification } = useNotification();

const form = ref({ email: "", password: "" });
useHead({ title: () => `${$t("auth.login.title")} - Hussbook` });

const loginRequest = useRequest<void, { email: string; password: string }>({
  keepLoading: true,
  promise: () => login(form.value.email, form.value.password),
  onSuccess: async () => {
    await fetchUser();
    await router.push(route.query?.redirect ? String(route.query?.redirect) : { name: "dashboard" });
  },
  onFailure: (response: AxiosResponse) => {
    if (response.status !== 422) {
      errorNotification("shared.fetch.error");
    }
    form.value.password = "";
  },
});
const oAuthLoginRequest = useRequest({
  keepLoading: true,
  promise: (provider: AuthOAuthProvider) => oAuthLogin(provider),
  onSuccess: async (response) => {
    location.href = response;
  },
  onFailure: () => errorNotification("shared.fetch.error"),
});

const submit = async () => {
  await loginRequest.fetch();
};
</script>
