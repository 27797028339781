<template>
  <template v-if="showBookingRequest.isLoading">
    <LoadingSection />
  </template>
  <template v-else-if="showBookingRequest.hasErrors">
    <ListErrorState />
  </template>
  <template v-else-if="showBookingRequest.data">
    <DashboardHeader>
      <DashboardBackTo :to="{ name: 'bookings' }">
        {{ $t("bookings.manage.back_to_list") }}
      </DashboardBackTo>
      <DashboardHeaderTitle>{{ $t("bookings.manage.title") }}</DashboardHeaderTitle>
      <DashboardHeaderActions>
        <ClipboardCopyId :value="showBookingRequest.data.id" />
        <BookingsDropdown
          :booking="showBookingRequest.data"
          @confirmed="showBookingRequest.fetch()"
          @cancelled="showBookingRequest.fetch()"
          @deleted="router.push({ name: 'bookings' })"
        >
          <DropdownMenuButton v-slot="{ toggle }">
            <button
              type="button"
              class="btn-white"
              @click="toggle"
            >
              <FontAwesomeIcon
                class="h-5 w-5 fill-slate-700"
                icon="ellipsis-light"
              />
            </button>
          </DropdownMenuButton>
        </BookingsDropdown>
      </DashboardHeaderActions>
    </DashboardHeader>
    <SectionGroup>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("bookings.manage.details.title") }}</SectionTitle>
        </SectionHeader>
        <BookingsDetails :booking="showBookingRequest.data" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("bookings.manage.extras.title") }}</SectionTitle>
        </SectionHeader>
        <BookingsExtrasTable :extras="showBookingRequest.data.extras" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("bookings.manage.payments.title") }}</SectionTitle>
        </SectionHeader>
        <BookingsPaymentsTable :payments="showBookingRequest.data.booking_payments" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("bookings.manage.booking_cleanings.title") }}</SectionTitle>
        </SectionHeader>
        <BookingsCleaningsTable :cleanings="showBookingRequest.data.booking_cleanings" />
      </section>
      <section v-if="showBookingRequest.data.guest">
        <SectionHeader>
          <SectionTitle>{{ $t("bookings.manage.guest.title") }}</SectionTitle>
        </SectionHeader>
        <BookingGuestsDetails :guest="showBookingRequest.data.guest" />
      </section>
    </SectionGroup>
  </template>
</template>

<script lang="ts" setup>
import BookingGuestsDetails from "@/contexts/booking-guests/ui/components/BookingGuestsDetails.vue";
import useBookings from "@/contexts/bookings/composables/useBookings";
import type { Booking } from "@/contexts/bookings/models/Booking";
import BookingsCleaningsTable from "@/contexts/bookings/ui/components/BookingsCleaningsTable.vue";
import BookingsDetails from "@/contexts/bookings/ui/components/BookingsDetails.vue";
import BookingsDropdown from "@/contexts/bookings/ui/components/BookingsDropdown.vue";
import BookingsExtrasTable from "@/contexts/bookings/ui/components/BookingsExtrasTable.vue";
import BookingsPaymentsTable from "@/contexts/bookings/ui/components/BookingsPaymentsTable.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import Errors from "@/contexts/shared/models/Errors";
import ClipboardCopyId from "@/contexts/shared/ui/components/clipboard/ClipboardCopyId.vue";
import DropdownMenuButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuButton.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const route = useRoute();
const router = useRouter();
const { showBooking } = useBookings();
const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "bookings" }));
useHead({ title: () => `${$t("bookings.manage.title")} - Hussbook` });

const bookingId = computed<string>(() => String(route.params.id));

onMounted(() => {
  showBookingRequest.fetch();
});

const showBookingRequest = useRequest<Booking>({
  initialLoading: true,
  promise: () => showBooking(bookingId.value),
  onFailure: (e) => {
    if (e === Errors.NOT_FOUND) {
      router.push({ name: "bookings" });
      return;
    }
  },
});
</script>
