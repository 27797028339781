<template>
  <button
    type="button"
    class="inline-flex items-center rounded-sm border border-transparent p-px text-sm leading-3 text-white shadow-xs focus:outline-hidden focus:ring-2 focus:ring-white"
    @click.prevent="bookingCleaningsManageModal?.open()"
  >
    <div class="flex items-center space-x-1.5">
      <template v-if="bookingCleaning && bookingCleaning.type">
        {{ $t(`booking_cleanings.cleaning_type.${bookingCleaning.type || "without"}`) }}
      </template>
      <FontAwesomeIcon
        v-else
        icon="plus-solid"
        class="h-3.5 w-3.5 fill-current"
      />
    </div>
  </button>
  <ModalDialog
    ref="bookingCleaningsManageModal"
    v-slot="{ close }"
    size="2xl"
  >
    <BookingCleaningsManageModal
      :assoc="assoc"
      :booking="booking"
      :booking-cleaning="bookingCleaning"
      @close="close"
      @updated-booking-cleaning="$emit('updatedBookingCleaning', { booking, cleaning: $event })"
    />
  </ModalDialog>
</template>

<script setup lang="ts">
import type { BookingCleaning, BookingCleaningAssoc } from "@/contexts/booking-cleanings/models/BookingCleaning";
import BookingCleaningsManageModal from "@/contexts/booking-cleanings/ui/modals/BookingCleaningsManageModal.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import type { ModalDialogInstance } from "@/types/instances";

const props = defineProps<{
  booking: Booking;
  assoc: BookingCleaningAssoc;
}>();

defineEmits<{
  updatedBookingCleaning: [value: { booking: Booking; cleaning: BookingCleaning }];
}>();

const { t: $t } = useI18n();

const bookingCleaningsManageModal = ref<ModalDialogInstance>();

const bookingCleaning = computed<BookingCleaning | undefined>(() => props.booking.booking_cleanings?.find((cleaning) => cleaning.assoc === props.assoc));
</script>
