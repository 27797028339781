import type { Account } from "@/contexts/accounts/models/Account";
import type { User } from "@/contexts/profile/models/User";
import i18n from "@/i18n";
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", () => {
  const authenticatedId = useStorage<string | null>("auth.authenticated.id", null);
  const authenticatedAccountId = useStorage<string | null>("auth.authenticated.account.id", null);
  const authenticatedUser = ref<User>();

  const isAuthenticated = computed<boolean>(() => !!authenticatedId.value);
  const accounts = computed<Account[]>(() => authenticatedUser.value?.accounts ?? []);
  const authenticatedAccount = computed<Account | null>(() => authenticatedUser.value?.accounts?.find((item) => item.id === authenticatedAccountId.value) ?? null);

  const setAuthenticatedUser = (user: User | undefined = undefined) => {
    authenticatedId.value = user?.id ?? null;
    authenticatedUser.value = user;
    if (user) {
      const browserLanguage = navigator.language?.substring(0, 2).toLowerCase();
      i18n.global.locale.value = user?.language && i18n.global.availableLocales.includes(user.language) ? user.language : browserLanguage && i18n.global.availableLocales.includes(browserLanguage) ? browserLanguage : "en";
    }
  };
  const changeAccount = (account?: Account) => {
    if (account) {
      accounts.value[accounts.value.findIndex((item) => item.id === account?.id)] = account;
    }
    authenticatedAccountId.value = account?.id ?? null;
    document.documentElement.style.setProperty("--color-primary", account?.primary_color ?? "#394fe1");
    document.documentElement.style.setProperty("--color-secondary", account?.secondary_color ?? "#1ce7b2");
  };

  return {
    accounts,
    changeAccount,
    isAuthenticated,
    authenticatedId,
    authenticatedUser,
    authenticatedAccount,
    setAuthenticatedUser,
    authenticatedAccountId,
  };
});
