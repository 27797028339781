<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-slate-800">
      {{ $t("settings.integrations.title") }}
    </div>
  </DashboardHeader>
  <BreadcrumbGroup>
    <BreadcrumbItem :to="{ name: 'settings' }">
      {{ $t("settings.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.integrations' }">
      {{ $t("settings.integrations.title") }}
    </BreadcrumbItem>
  </BreadcrumbGroup>
  <div class="space-y-3">
    <template v-if="getAccountIntegrationsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else>
      <LoadingAbsolute :is-loading="getAccountIntegrationsRequest.isLoading">
        <div class="space-y-2">
          <template
            v-for="(integration, index) in getAccountIntegrationsRequest.data.integrations"
            :key="index"
          >
            <AccountIntegrationsIntegration
              :credentials="integration.credentials"
              :integration="getAccountIntegrationsRequest.data.credentials.find((item) => item.provider === integration.provider)"
              :provider="integration.provider"
              @created="getAccountIntegrationsRequest.data.credentials.push($event)"
              @disabled="getAccountIntegrationsRequest.data.credentials[getAccountIntegrationsRequest.data.credentials.findIndex((item) => item.provider === integration.provider)].enabled = false"
              @enabled="getAccountIntegrationsRequest.data.credentials[getAccountIntegrationsRequest.data.credentials.findIndex((item) => item.provider === integration.provider)].enabled = true"
              @updated="getAccountIntegrationsRequest.data.credentials[getAccountIntegrationsRequest.data.credentials.findIndex((item) => item.provider === integration.provider)] = $event"
            />
          </template>
        </div>
      </LoadingAbsolute>
    </template>
  </div>
</template>

<script lang="ts" setup>
import useAccountIntegrations from "@/contexts/accounts/composables/useAccountIntegrations";
import type { AccountIntegration, AccountIntegrations } from "@/contexts/accounts/models/AccountIntegration";
import AccountIntegrationsIntegration from "@/contexts/accounts/ui/components/AccountIntegrationsIntegration.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import BreadcrumbGroup from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbGroup.vue";
import BreadcrumbItem from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbItem.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingAbsolute from "@/contexts/shared/ui/components/loading/LoadingAbsolute.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const router = useRouter();
const accountBus = useEventBus<string>("account");
const { getAccountIntegrations } = useAccountIntegrations();

accountBus.on(() => router.push({ name: "settings" }));
useHead({ title: () => `${$t("settings.integrations.title")} - Hussbook` });

onMounted(() => {
  getAccountIntegrationsRequest.fetch();
});

const getAccountIntegrationsRequest = useRequest<{ credentials: AccountIntegration[]; integrations: AccountIntegrations }>({
  value: { credentials: [], integrations: [] },
  initialLoading: true,
  promise: () => getAccountIntegrations(),
});
</script>
