<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.kitchens") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <div class="space-y-2.5">
        <template
          v-for="(item, index) in input"
          :key="index"
        >
          <div class="relative gap-2 rounded-sm border border-slate-200 p-3">
            <ButtonWithIcon
              class="absolute right-0 top-0 m-1 p-2"
              icon="trash-can-solid"
              @click="removeKitchen(index)"
            />
            <div class="text-sm font-medium text-slate-800">{{ $t(`accommodations.manage.kitchens.name`, { number: index + 1 }) }}</div>
            <div class="mt-2 grid gap-2 md:grid-cols-2">
              <FormGroup :errors="updateAccommodationKitchensRequest.errors?.[`kitchens.${index}.type`]">
                <FormLabel>{{ $t("accommodations.manage.kitchens.props.type") }}</FormLabel>
                <FormInput>
                  <FormSelect
                    v-model="input[index].type"
                    :options="kitchenTypeOptions"
                    name="type"
                  />
                </FormInput>
              </FormGroup>
              <FormGroup :errors="updateAccommodationKitchensRequest.errors?.[`kitchens.${index}.floor`]">
                <FormLabel>{{ $t("accommodations.manage.kitchens.props.floor") }}</FormLabel>
                <FormInput>
                  <FormInteger
                    v-model="input[index].floor"
                    name="floor"
                  />
                </FormInput>
              </FormGroup>
            </div>
          </div>
        </template>
        <div class="flex justify-center md:col-span-2">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="addKitchen"
          >
            {{ $t("accommodations.manage.kitchens.add") }}
          </ButtonWithIcon>
        </div>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationKitchen, UnsavedAccommodationKitchen } from "@/contexts/accommodations/models/AccommodationRooms";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Option } from "@/contexts/shared/models/Select";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
  kitchens: AccommodationKitchen[];
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationKitchens } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.kitchens);
});

const input = ref<UnsavedAccommodationKitchen[]>([]);
const kitchenTypeOptions = computed<Option[]>(() => ["open_plan", "outdoor", "separate", "kitchenette"].map((item) => ({ value: item, label: $t(`accommodations.manage.kitchens.type.${item}`) })));

const updateAccommodationKitchensRequest = useRequest({
  promise: () => updateAccommodationKitchens(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const addKitchen = () => {
  input.value.push({ type: "separate", floor: null });
};
const removeKitchen = (index: number) => {
  input.value.splice(index, 1);
};
const submit = async () => {
  await updateAccommodationKitchensRequest.fetch();
};
</script>
