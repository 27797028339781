<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t(title, Number(count ?? 0)) }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <p class="text-sm text-slate-500">
      {{ $t(description) }}
    </p>
  </ModalDialogContent>
  <ModalDialogFooter>
    <ButtonWithIcon
      :disabled="isLoading"
      class="btn-red"
      icon="trash-can-solid"
      tabindex="1"
      @click="$emit('confirm')"
    >
      {{ $t("shared.form.delete") }}
    </ButtonWithIcon>
    <button
      class="btn-white"
      tabindex="2"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

defineProps<{
  title: string;
  description: string;
  isLoading: boolean;
  count?: number;
}>();

defineEmits<{
  close: [];
  confirm: [];
}>();

const { t: $t } = useI18n();
</script>
