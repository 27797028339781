<template>
  <div
    :class="{ 'h-96': !customHeight }"
    class="flex w-full"
  >
    <div class="m-auto w-80">
      <div class="flex">
        <div class="mb-4 w-auto rounded-sm bg-slate-100 p-2">
          <FontAwesomeIcon
            :icon="icon"
            class="h-7 w-7 fill-primary"
          />
        </div>
      </div>
      <div class="mb-1.5 text-lg font-semibold text-slate-800">
        {{ $t(title) }}
      </div>
      <div class="mb-4 text-xs text-slate-700">
        {{ $t(description) }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FontAwesomeIconTypes } from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  icon: FontAwesomeIconTypes;
  title: string;
  description: string;
  customHeight?: boolean;
}>();

const { t: $t } = useI18n();
</script>
