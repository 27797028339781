<template>
  <FilterItemButton @clear="clear">
    <div
      v-if="!empty"
      class="max-w-[15rem] truncate whitespace-nowrap pl-2 text-primary"
    >
      {{ placeholder }}
    </div>
  </FilterItemButton>
  <FilterContent v-slot="{ close }">
    <div class="flex items-center space-x-2">
      <FormDatePicker
        v-model="from"
        class="w-full"
        name="from"
      />
      <div class="text-sm text-slate-700">
        {{ $t("shared.filters.date_range.and") }}
      </div>
      <FormDatePicker
        v-model="to"
        :min-date="from ?? undefined"
        class="w-full"
        name="to"
      />
    </div>
    <button
      :disabled="!from || !to"
      class="btn-primary flex w-full items-center py-1"
      type="submit"
      @click="apply(close)"
    >
      <div class="mx-auto">
        {{ $t("shared.filters.apply") }}
      </div>
    </button>
  </FilterContent>
</template>

<script lang="ts" setup>
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { DateRange } from "@/contexts/shared/models/DateRange";
import FilterContent from "@/contexts/shared/ui/components/filter/FilterContent.vue";
import FilterItemButton from "@/contexts/shared/ui/components/filter/FilterItemButton.vue";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import type { ComputedRef, WritableComputedRef } from "vue";

const { t: $t } = useI18n();
const dayjs = useDayjs();

const empty = inject<ComputedRef<boolean>>("empty");
const required = inject<ComputedRef<boolean>>("required");
const input = inject<WritableComputedRef<DateRange | undefined>>("input") as WritableComputedRef<DateRange | undefined>;
const placeholder = computed<string>(() => `${dayjs(input.value?.from).toDate().toLocaleDateString()}-${dayjs(input.value?.to).toDate().toLocaleDateString()}`);

const from = ref<Date | null>(input.value?.from ? new Date(input.value?.from) : null);
const to = ref<Date | null>(input.value?.to ? new Date(input.value?.to) : null);

watch(input, (value) => {
  from.value = value?.from ?? null;
  to.value = value?.to ?? null;
});

const apply = (close: () => void) => {
  input.value = from.value && to.value ? { from: from.value, to: to.value } : undefined;
  close();
};
const clear = () => {
  if (required?.value) return;
  input.value = undefined;
};
</script>
