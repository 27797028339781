<template>
  <div class="relative">
    <template v-if="!showMap">
      <div
        class="h-80 bg-cover bg-center bg-no-repeat"
        :style="{ backgroundImage: `url(/assets/images/maps.png)` }"
      />
      <div class="absolute top-0 z-10 flex h-full w-full bg-slate-300/50">
        <div class="m-auto">
          <ButtonWithIcon
            class="btn-white"
            icon="location-dot-solid"
            @click="displayMap"
          >
            {{ $t("shared.map.show") }}
          </ButtonWithIcon>
        </div>
      </div>
    </template>
    <MapRoot
      v-show="showMap"
      ref="map"
      class="h-80"
      with-coordinates
    />
  </div>
</template>

<script lang="ts" setup>
import type { GMapsCoordinates } from "@/contexts/shared/composables/useMaps";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import MapRoot from "@/contexts/shared/ui/components/map/MapRoot.vue";
import type { MapRootInstance } from "@/types/instances";

const props = defineProps<{
  coordinates: GMapsCoordinates;
}>();

const { t: $t } = useI18n();

const showMap = ref<boolean>(false);
const map = ref<MapRootInstance>();

const displayMap = () => {
  showMap.value = true;
  map?.value?.load();
  map?.value?.setZoom(16);
  map?.value?.setCenter(props.coordinates);
  map?.value?.addMarker(props.coordinates);
};

watch(
  () => props.coordinates,
  (value) => {
    if (showMap.value) {
      map?.value?.setCenter(value);
      map?.value?.addMarker(value);
    }
  },
);
</script>
