<template>
  <div class="mb-6 w-full rounded-sm bg-white p-8 shadow-xl md:p-10">
    <SharedAlert
      variant="info"
      description="We have temporarily disabled automatic registration of new accounts, you can contact us to create a new account."
      title="Automatic registration is currently disabled"
    />
  </div>
  <div class="px-4">
    <p class="text-sm text-slate-700">
      {{ $t("auth.register.login.already_registered") }}
      <RouterLink
        :to="{ name: 'login' }"
        class="text-primary hover:text-slate-800"
      >
        {{ $t("auth.register.login.to_login") }}
      </RouterLink>
    </p>
  </div>
</template>

<script lang="ts" setup>
import SharedAlert from "@/contexts/shared/ui/components/alert/SharedAlert.vue";

const { t: $t } = useI18n();
useHead({ title: () => `${$t("auth.register.title")} - Hussbook` });
</script>
