<template>
  <div class="rounded-md bg-primary/5 p-3">
    <div class="flex">
      <div class="shrink-0 pt-0.5">
        <FontAwesomeIcon
          class="h-5 w-5 fill-primary/90"
          icon="square-info-solid"
        />
      </div>
      <div class="ml-2.5 w-full">
        <h3 class="text-sm font-medium text-primary">{{ $t(title) }}</h3>
        <div class="mt-1.5 text-sm text-primary/80">
          <p>{{ $t(description) }}</p>
        </div>
      </div>
      <div class="shrink-0">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  variant: "info";
  title: string;
  description: string;
}>();

const { t: $t } = useI18n();
</script>
