<template>
  <template v-if="bathrooms.length === 0">
    <SectionEmptyState>
      {{ $t("accommodations.manage.bathrooms.empty") }}
    </SectionEmptyState>
  </template>
  <template v-else>
    <div class="grid grid-cols-2 place-items-stretch gap-2.5">
      <template v-for="(items, group) in bathroomsGroup">
        <template
          v-for="(bathroom, index) in items"
          :key="index"
        >
          <div class="min-h-24 rounded-sm border border-slate-200 p-2.5">
            <div class="text-sm font-medium text-slate-800">{{ $t(`accommodations.manage.bathrooms.name.${group}`, { number: index + 1 }) }}</div>
            <div class="mt-1.5 text-sm text-slate-500">
              <div v-if="bathroom.toilet !== 'no_toilet'">{{ $t(`accommodations.manage.bathrooms.toilet.type.${bathroom.toilet}`) }}</div>
              <div v-if="bathroom.shower !== 'no_shower'">{{ $t(`accommodations.manage.bathrooms.shower.type.${bathroom.shower}`) }}</div>
              <div v-if="bathroom.bath !== 'no_bath'">{{ $t(`accommodations.manage.bathrooms.bath.type.${bathroom.bath}`) }}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </template>
</template>

<script lang="ts" setup>
import type { AccommodationBathroom } from "@/contexts/accommodations/models/AccommodationRooms";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import { groupBy } from "lodash-es";

const props = defineProps<{
  bathrooms: AccommodationBathroom[];
}>();

const { t: $t } = useI18n();

const bathroomsGroup = computed<Record<string, AccommodationBathroom[]>>(() => groupBy(props.bathrooms, "type"));
</script>
