<template>
  <form
    class="grid gap-3.5 md:grid-cols-2"
    @submit.prevent="$emit('submit')"
  >
    <FormGroup :errors="errors.address">
      <FormLabel>{{ $t("owner_bank_accounts.props.address") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.address"
          name="address"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.id_number">
      <FormLabel>{{ $t("owner_bank_accounts.props.id_number") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.id_number"
          name="id_number"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.country_code">
      <FormLabel>{{ $t("owner_bank_accounts.props.country_code") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.country_code"
          :options="useCountry().countryOptions"
          name="country_code"
        />
      </FormInput>
    </FormGroup>
    <div class="relative h-full overflow-hidden rounded-sm border border-green-600/50 bg-green-100/20 px-4 pb-4 pt-3 md:col-span-2">
      <div class="absolute bottom-0 right-0 z-0 pb-4 pr-4">
        <FontAwesomeIcon
          class="size-12 fill-green-600/20"
          icon="lock-keyhole-solid"
        />
      </div>
      <div class="z-10 grid gap-3.5 md:grid-cols-2">
        <FormGroup :errors="errors.bank_name">
          <FormLabel>{{ $t("owner_bank_accounts.props.bank_name") }}</FormLabel>
          <FormInput>
            <FormText
              v-model="input.bank_name"
              name="bank_name"
            />
          </FormInput>
        </FormGroup>
        <FormGroup :errors="errors.bank_account">
          <FormLabel>{{ $t("owner_bank_accounts.props.bank_account") }}</FormLabel>
          <FormInput>
            <FormText
              v-model="input.bank_account"
              name="bank_account"
            />
          </FormInput>
        </FormGroup>
        <FormGroup :errors="errors.bank_swift_bic">
          <FormLabel>{{ $t("owner_bank_accounts.props.bank_swift_bic") }}</FormLabel>
          <FormInput>
            <FormText
              v-model="input.bank_swift_bic"
              name="bank_swift_bic"
            />
          </FormInput>
        </FormGroup>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import type { UnsavedOwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import useCountry from "@/contexts/shared/composables/useCountry";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  errors: RequestErrors<UnsavedOwnerBankAccount>;
  type: "create" | "update";
}>();

defineEmits<{
  submit: [];
}>();

const input = defineModel<UnsavedOwnerBankAccount>({ default: { address: "", country_code: "", id_number: "", bank_name: "", bank_account: "", bank_swift_bic: "" } });

const { t: $t } = useI18n();
</script>
