<template>
  <form
    class="space-y-3.5"
    @submit.prevent="$emit('submit')"
  >
    <FormGroup
      id="booking_imports.create.provider"
      :errors="errors.provider"
    >
      <FormLabel>{{ $t("booking_imports.props.provider") }}</FormLabel>
      <FormChoice
        v-model="form.provider"
        :options="options"
      >
        <template #default="{ option }">
          <ProviderIcon
            :icon="option.value as ProviderIconTypes"
            class="mx-auto mb-2 size-10"
          />
          <span class="text-sm font-medium text-slate-700">{{ $t(option.label) }}</span>
        </template>
      </FormChoice>
    </FormGroup>
    <FormGroup
      id="booking_imports.create.file"
      :errors="errors.file"
    >
      <FormLabel>{{ $t("booking_imports.create.props.file") }}</FormLabel>
      <FormFile
        v-model="form.file"
        :allowed-extensions="allowedExtensions"
        :max-size="5000000"
      />
    </FormGroup>
    <input
      class="hidden"
      type="submit"
    />
  </form>
</template>

<script setup lang="ts">
import type { BookingImportProvider } from "@/contexts/booking-imports/models/BookingImport";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import type { Choice } from "@/contexts/shared/models/Choice";
import FormChoice from "@/contexts/shared/ui/components/form/FormChoice.vue";
import FormFile from "@/contexts/shared/ui/components/form/FormFile.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import ProviderIcon, { type ProviderIconTypes } from "@/contexts/shared/ui/components/icon/ProviderIcon.vue";

defineProps<{
  errors: RequestErrors<{ provider: BookingImportProvider | undefined; file: File | undefined }>;
}>();

defineEmits<{
  submit: [];
}>();

const form = defineModel<{ provider: BookingImportProvider | undefined; file: File | undefined }>({ default: { provider: undefined, file: undefined } });

const { t: $t } = useI18n();

const options = computed<Choice<BookingImportProvider>[]>(() => [{ value: "club_villamar", label: "shared.providers.club_villamar" }]);
const allowedExtensions = computed(() => ["csv"]);
</script>
