<template>
  <div class="flex items-center space-x-3">
    <template v-if="type === 'deep'">
      <div class="h-3.5 w-3.5 rounded-sm bg-cyan-500" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.deep") }}</div>
    </template>
    <template v-else-if="type === 'in'">
      <div class="h-3.5 w-3.5 rounded-sm bg-indigo-500" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.in") }}</div>
    </template>
    <template v-else-if="type === 'out'">
      <div class="h-3.5 w-3.5 rounded-sm bg-emerald-500" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.out") }}</div>
    </template>
    <template v-else-if="type === 'swap'">
      <div class="h-3.5 w-3.5 rounded-sm bg-blue-500" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.swap") }}</div>
    </template>
    <template v-else-if="type === 'final'">
      <div class="h-3.5 w-3.5 rounded-sm bg-teal-500" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.final") }}</div>
    </template>
    <template v-else>
      <div class="h-3.5 w-3.5 rounded-sm bg-slate-200" />
      <div v-if="!withoutText">{{ $t("booking_cleanings.cleaning_type.without") }}</div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { BookingCleaningType } from "@/contexts/booking-cleanings/models/BookingCleaningType";

defineProps<{
  type: BookingCleaningType | null;
  withoutText?: boolean;
}>();

const { t: $t } = useI18n();
</script>
