<template>
  <div
    :class="{ 'xl:border-l': week > 26 }"
    class="flex items-stretch divide-x divide-slate-200 border-b border-slate-200 hover:bg-slate-50"
  >
    <div class="flex w-8 flex-none px-1 py-1.5 text-center font-medium tabular-nums text-slate-800">
      <div class="m-auto">{{ week }}</div>
    </div>
    <div class="hidden grow p-1.5 text-sm tabular-nums text-slate-600 sm:flex">
      <div class="my-auto">{{ `${start} - ${end}` }}</div>
    </div>
    <div class="hidden w-36 flex-none p-1.5 md:flex">
      <div class="my-auto">
        <template v-if="season?.type">
          <Badge :class="accountSeasonTypeBadge">
            {{ $t(`accounts.seasons.type.${season.type}`) }}
          </Badge>
        </template>
      </div>
    </div>
    <div class="hidden w-16 flex-none px-1 py-1.5 md:flex">
      <div class="m-auto">
        <template v-if="season?.amount">
          <Badge class="badge-gray mx-auto tabular-nums">
            {{ `${Number(season?.amount).toFixed(0)}%` }}
          </Badge>
        </template>
      </div>
    </div>
    <div class="flex flex-none p-1.5">
      <FormCheckbox
        :id="week"
        :model-value="!!modelValue"
        class="my-auto"
        name="enabled"
        @update:model-value="toggleWeek"
      />
    </div>
    <div class="flex w-28 flex-none space-x-1.5 p-1.5">
      <FormFloat
        :disabled="!modelValue"
        :max="100"
        :min="0"
        :model-value="modelValue?.amount ?? null"
        class="my-auto"
        name="amount"
        @update:model-value="updateModelValue('amount', $event ? Number($event) : null)"
      />
      <span class="my-auto text-sm text-slate-600">%</span>
    </div>
    <div class="flex w-24 flex-none px-2.5 py-1.5">
      <template v-if="modelValue?.amount">
        <div class="my-auto text-sm text-slate-900">
          {{ Number((referencePrice * (modelValue?.amount ?? 0)) / 100).toFixed(2) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AccommodationContractWeek } from "@/contexts/accommodations/models/AccommodationContract";
import type { AccountSeason } from "@/contexts/accounts/models/AccountSeason";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import FormFloat from "@/contexts/shared/ui/components/form/FormFloat.vue";

const props = defineProps<{
  modelValue: AccommodationContractWeek | null | undefined;
  year: number;
  week: number;
  referencePrice: number;
  season: AccountSeason | null;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: AccommodationContractWeek | null];
}>();

const { t: $t } = useI18n();
const dayjs = useDayjs();

const start = computed(() => dayjs().year(props.year).week(props.week).startOf("week").format("L"));
const end = computed(() => dayjs().year(props.year).week(props.week).endOf("week").format("L"));

const accountSeasonTypeBadge = computed<string | undefined>(() => {
  switch (props.season?.type) {
    case "f":
      return "bg-slate-100 text-slate-700";
    case "e":
      return "bg-blue-100 text-blue-700";
    case "d":
      return "bg-yellow-100 text-yellow-700";
    case "c":
      return "bg-orange-100 text-orange-700";
    case "b":
      return "bg-red-100 text-red-700";
    case "a":
      return "bg-fuchsia-100 text-fuchsia-700";
  }
});

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const toggleWeek = ($event: any[] | Set<any> | boolean | undefined) => {
  emit("update:modelValue", $event ? { amount: null } : null);
};
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const updateModelValue = (key: keyof AccommodationContractWeek, $event: any) => {
  const value = props.modelValue ?? { amount: null };
  value[key] = $event;
  emit("update:modelValue", value);
};
</script>
