<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.extras") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <div class="space-y-2.5">
        <div class="grid gap-2 lg:grid-cols-2">
          <template
            v-for="(item, index) in input"
            :key="index"
          >
            <div class="relative gap-2 rounded-sm border border-slate-200 p-3">
              <ButtonWithIcon
                class="absolute right-0 top-0 m-1 p-2"
                icon="trash-can-solid"
                @click="removeExtra(index)"
              />
              <AccommodationsExtraForm
                v-model="input[index]"
                :index="index"
                :input="input"
                :errors="updateAccommodationExtrasRequest.errors"
              />
            </div>
          </template>
        </div>
        <div class="flex justify-center md:col-span-2">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="addExtra"
          >
            {{ $t("accommodations.manage.extras.add") }}
          </ButtonWithIcon>
        </div>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { AccommodationExtra, UnsavedAccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import AccommodationsExtraForm from "@/contexts/accommodations/ui/components/AccommodationsExtraForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accommodation: Accommodation;
  extras: AccommodationExtra[];
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationExtras } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.extras);
});

const input = ref<UnsavedAccommodationExtra[]>([]);

const updateAccommodationExtrasRequest = useRequest({
  promise: () => updateAccommodationExtras(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const addExtra = () => {
  input.value.push({ name: "final_cleaning", mode: "per_unit", type: "selectable", payment_time: "on_booking", price: { price: 0, type: "fixed" }, conditions: { applicable_period: null, number_of_nights: null }, maximum_units: null });
};
const removeExtra = (index: number) => {
  input.value.splice(index, 1);
};
const submit = async () => {
  await updateAccommodationExtrasRequest.fetch();
};
</script>
