<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
    @after-leave="leave"
  >
    <DialogRoot
      as="div"
      class="relative z-30"
      @close="preventClose ? void 0 : close()"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-slate-500/75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="classes"
              class="relative w-full transform rounded-lg bg-white text-left shadow-2xl transition-all sm:my-8"
            >
              <template v-if="!withoutDismiss">
                <div class="absolute right-0 top-0 block pr-3.5 pt-3.5">
                  <button
                    class="rounded-sm focus:outline-hidden focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    type="button"
                    @click="close"
                  >
                    <FontAwesomeIcon
                      class="h-5 w-5 fill-slate-600 hover:fill-slate-500"
                      icon="xmark-solid"
                    />
                  </button>
                </div>
              </template>
              <slot v-bind="{ open, close }" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </DialogRoot>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import { DialogPanel, Dialog as DialogRoot, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type { RouteLocationRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    parentRoute?: RouteLocationRaw;
    route?: boolean;
    withoutDismiss?: boolean;
    preventClose?: boolean;
    size?: "medium" | "large" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "full";
  }>(),
  {
    parentRoute: undefined,
    size: "medium",
  },
);

const emit = defineEmits<{
  open: [];
  opened: [];
  close: [];
  closed: [];
}>();

const router = useRouter();
const isOpen = ref(false);
const isClosed = ref(false);

onMounted(() => {
  if (props.route) {
    open();
  }
});

const open = () => {
  emit("open");
  isOpen.value = true;
  setTimeout(() => emit("opened"), 550);
};
const close = () => {
  emit("close");
  isOpen.value = false;
};
const leave = () => {
  if (!isClosed.value) {
    isClosed.value = true;
    emit("closed");
    if (props.parentRoute) {
      router.push(props.parentRoute);
    }
  }
};
const classes = computed(() => ({
  "max-w-md": props.size === "medium",
  "max-w-lg": props.size === "large",
  "max-w-xl": props.size === "xl",
  "max-w-2xl": props.size === "2xl",
  "max-w-3xl": props.size === "3xl",
  "max-w-4xl": props.size === "4xl",
  "max-w-5xl": props.size === "5xl",
  "max-w-6xl": props.size === "6xl",
  "max-w-7xl": props.size === "7xl",
  "max-w-full": props.size === "full",
}));

provide("close", close);

defineExpose({
  open,
  close,
  leave,
});
</script>
