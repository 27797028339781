<template>
  <textarea
    :id="id"
    v-model="input"
    :class="classes"
    :name="name"
    :placeholder="placeholder"
  />
</template>

<script lang="ts" setup>
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue: string | null;
    padding?: boolean;
    placeholder?: string;
  }>(),
  {
    padding: true,
    placeholder: undefined,
  },
);
const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const input = computed<string | undefined>({
  get: () => props.modelValue ?? undefined,
  set: (value) => emit("update:modelValue", value ?? null),
});
const classes = computed(() => ({
  "block w-full rounded-sm shadow-xs text-sm": true,
  "px-2 py-1": props.padding,
  "border-slate-300 focus:border-primary focus:ring-primary": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:ring-red-500 text-red-900 placeholder-red-300": hasErrors.value,
}));
</script>
