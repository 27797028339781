<template>
  <div class="flex items-center">
    <div class="mr-1 mt-0.5 text-xs leading-3 text-slate-500">
      {{ value }}
    </div>
    <button
      type="button"
      @click.prevent="copyId"
    >
      <FontAwesomeIcon
        class="h-3.5 w-3.5 fill-slate-500"
        icon="clipboard-solid"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import useClipboard from "@/contexts/shared/composables/useClipboard";
import useNotification from "@/contexts/shared/composables/useNotification";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

const props = defineProps<{
  value: string;
}>();

const { copyToClipboard } = useClipboard();
const { simpleNotification, errorNotification } = useNotification();
const copyId = async () => {
  try {
    await copyToClipboard(props.value);
    simpleNotification("shared.clipboard.success");
  } catch (e) {
    errorNotification("shared.clipboard.error");
  }
};
</script>
