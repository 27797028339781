<template>
  <template v-if="kitchens.length === 0">
    <SectionEmptyState>
      {{ $t("accommodations.manage.kitchens.empty") }}
    </SectionEmptyState>
  </template>
  <template v-else>
    <div class="grid grid-cols-2 place-items-stretch gap-2.5">
      <template
        v-for="(kitchen, index) in kitchens"
        :key="index"
      >
        <div class="min-h-24 rounded-sm border border-slate-200 p-2.5">
          <div class="text-sm font-medium text-slate-800">{{ $t(`accommodations.manage.kitchens.name`, { number: index + 1 }) }}</div>
          <div class="mt-1.5 text-sm text-slate-500">
            <div>{{ $t(`accommodations.manage.kitchens.type.${kitchen.type}`) }}</div>
          </div>
        </div>
      </template>
    </div>
  </template>
</template>

<script lang="ts" setup>
import type { AccommodationKitchen } from "@/contexts/accommodations/models/AccommodationRooms";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";

defineProps<{
  kitchens: AccommodationKitchen[];
}>();

const { t: $t } = useI18n();
</script>
