<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("booking_imports.warnings.modal.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="shrink-0">
          <FontAwesomeIcon
            icon="triangle-exclamation-solid"
            class="size-5 fill-yellow-400"
          />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">{{ $t("booking_imports.warnings.modal.alert") }}</h3>
          <div class="mt-2 text-sm text-yellow-700">
            <ul class="list-outside list-disc">
              <template
                v-for="(warning, index) in warnings"
                :key="index"
              >
                <li>{{ warning }}</li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ModalDialogContent>
</template>

<script setup lang="ts">
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";

defineProps<{
  warnings: string[];
}>();

defineEmits<{
  close: [];
}>();

const { t: $t } = useI18n();
</script>
