<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <PopoverPanel
      v-slot="{ close }"
      class="absolute z-20"
      focus
    >
      <div class="left-0 w-72 pt-1">
        <div class="h-full w-full space-y-3.5 rounded-sm border border-slate-200 bg-white px-3 py-2 shadow-sm">
          <div class="text-sm font-semibold text-slate-800">
            {{ $t(title) }}
          </div>
          <slot v-bind="{ close }" />
        </div>
      </div>
    </PopoverPanel>
  </transition>
</template>

<script lang="ts" setup>
import { PopoverPanel } from "@headlessui/vue";

const { t: $t } = useI18n();

const title = inject<string>("title", "");
</script>
