<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
  >
    <div
      v-show="selected?.length ?? 0 > 0"
      class="pointer-events-none absolute top-5 flex w-full"
    >
      <div class="pointer-events-auto z-10 ml-10 flex w-full flex-col items-center justify-between gap-2.5 rounded-md border border-slate-200 bg-white px-3 py-2 shadow-lg sm:mx-10 sm:flex-row xl:mx-auto xl:w-2/3">
        <div class="text-sm text-slate-700">
          {{ $t("shared.selected", selected?.length ?? 0) }}
        </div>
        <div class="flex gap-2.5">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
defineProps<{
  selected?: string[];
}>();

const { t: $t } = useI18n();
</script>
