<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-slate-800">
      {{ $t("settings.account.seasons.title") }}
    </div>
  </DashboardHeader>
  <BreadcrumbGroup>
    <BreadcrumbItem :to="{ name: 'settings' }">
      {{ $t("settings.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.account' }">
      {{ $t("settings.account.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.account.seasons' }">
      {{ $t("settings.account.seasons.title") }}
    </BreadcrumbItem>
  </BreadcrumbGroup>
  <div class="space-y-3">
    <template v-if="searchAccountSeasonsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else>
      <LoadingAbsolute :is-loading="searchAccountSeasonsRequest.isLoading">
        <div class="flex items-center justify-between border-x border-t border-slate-200 p-1.5 font-medium text-slate-800">
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="angle-left-solid"
              @click="() => criteria.year--"
            />
          </div>
          <div>{{ criteria.year }}</div>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="angle-right-solid"
              @click="() => criteria.year++"
            />
          </div>
        </div>
        <div class="gap-0 border-x border-t border-slate-200 lg:columns-2">
          <template
            v-for="week in Object.keys(searchAccountSeasonsRequest.data)"
            :key="week"
          >
            <AccountSeasonsWeekForm
              :season="searchAccountSeasonsRequest.data[Number(week)]"
              :week="Number(week)"
              :year="criteria.year"
              @updated="searchAccountSeasonsRequest.data[Number(week)] = $event"
            />
          </template>
        </div>
      </LoadingAbsolute>
    </template>
  </div>
</template>

<script lang="ts" setup>
import useAccountSeasons from "@/contexts/accounts/composables/useAccountSeasons";
import type { AccountSeason } from "@/contexts/accounts/models/AccountSeason";
import AccountSeasonsWeekForm from "@/contexts/accounts/ui/components/AccountSeasonsWeek.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import BreadcrumbGroup from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbGroup.vue";
import BreadcrumbItem from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbItem.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingAbsolute from "@/contexts/shared/ui/components/loading/LoadingAbsolute.vue";
import { useEventBus } from "@vueuse/core";

const { t: $t } = useI18n();
const router = useRouter();
const { searchAccountSeasons } = useAccountSeasons();
const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "settings" }));
useHead({ title: () => `${$t("settings.account.seasons.title")} - Hussbook` });

const { criteria } = useCriteria<{ year: number }>({ year: new Date().getFullYear() }, () => searchAccountSeasonsRequest.fetch());

onMounted(() => {
  searchAccountSeasonsRequest.fetch();
});

const searchAccountSeasonsRequest: UseRequest<Record<number, AccountSeason | null>> = useRequest<Record<number, AccountSeason | null>>({
  initialLoading: true,
  value: [],
  promise: () => searchAccountSeasons(criteria.value.year),
});
</script>
